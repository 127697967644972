import { checkAccountExists, createAccount, confirmAccount } from "@/services/register/index.js";
import { showNotificationError, showNotificationSuccess } from "@/services/notifications/index.js";

const state = {
    formDataStep1: {
        name: "",
        phone: "",
        inscription: "",
        birthdate:{
            day: "",
            month: "",
            year: ""
        }
    },
    formDataStep2: {
        email: "",
        username: "",
        nickname: "",
        password: "",
        confirmationPassword: "",
    },
    formDataStep3: {
        confirmationCode: ["", "", "", ""]
    },
    config: {
        app: 'suprema',
        agent: 1154131,
        approve: true,
        createFichasAccount: true,
        emailConfirmationFichas: false,
        emailConfirmationApp: true,
        club: 146
    },
    loadingStep: false,
    currentStep:1,
    loginInUse: false,
};
const mutations = {
    SET_FORM_DATA_STEP_1(state, formData) {
        state.formDataStep1 = formData;
    },
    SET_FORM_DATA_STEP_2(state, formData) {
        state.formDataStep2 = formData;
    },
    RESET_FORM_DATA_STEP_3(state) {
        state.formDataStep3 = {
            confirmationCode: ["", "", "", ""]
        }
    },
    ADD_STEP_SAVE(state, step) {
        state.stepsSaves.push(step);
    },
    SET_CURRENT_STEP(state, step) {
        state.currentStep = step;
    },
    SET_LOADING_STEP(state, loading) {
        state.loadingStep = loading;

    },
    SET_LOGIN_IN_USE(state, value) {
        state.loginInUse = value;

    },
    SET_RESET_BIRTHDATE(state) {
        state.formDataStep1.birthdate = {
            day: "",
            month: "",
            year: ""
        }
    
    }
};

const actions = {
    setLoginInUse({ commit }, value) {
        commit("SET_LOGIN_IN_USE", value);
    },
    async saveStepNonValidation({ commit, state }, router) {
        if (state.currentStep < 3) {
            commit("SET_CURRENT_STEP", state.currentStep + 1);
        } else if (state.currentStep == 3) {
            console.log("fui para etapa de compra")
            commit("purchase/SET_SHOW_STEPS", true, { root: true })
            router.push({ path: "/buy-chips", query: { skipAuth: true } });

        }
    },
    async saveStep({ commit, state, rootState }, router) {
        commit("SET_LOADING_STEP", true);
        const config = rootState.configs.config;
        let data;
        const nextStep = parseInt(state.currentStep) + 1;
        const previousStep = parseInt(state.currentStep) - 1;
        if (state.currentStep == 1) {
            console.log("aqui")
            data = state.formDataStep1;
            data.step = state.currentStep;
            data.inscription = data.inscription.replaceAll(".", "").replaceAll("-", "")
            data.phone = data.phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")
            data.birthdate = `${data.birthdate.year}-${data.birthdate.month}-${data.birthdate.day}`
            data.createFichasAccount = config.createFichasAccount
            try {
                let accountExists = await checkAccountExists(data, state.currentStep);
                if (!accountExists.exists) {
                    commit("SET_CURRENT_STEP", nextStep);
                    router.push("/register-form/" + nextStep);
                    
                } 
                else showNotificationError('Já existe uma conta com esses dados.')
                commit("SET_LOADING_STEP", false);

            } catch (error) {
                let errorMessage = error?.response?.data?.message || 'Ocorreu um erro ao verificar os dados. Tente novamente mais tarde'
                showNotificationError(errorMessage)
                commit("SET_LOADING_STEP", false);
                console.log("Erro que deu: ", errorMessage)
                commit("SET_RESET_BIRTHDATE")
            }

        } else if (state.currentStep == 2) {
            console.log("state.formDataStep2", state.formDataStep2)
            let data = { ...state.formDataStep1, ...state.formDataStep2, ...config };
            data.nickname = data.username
            data.step = state.currentStep
            data.inscription = data.inscription.replaceAll(".", "").replaceAll("-", "")
            data.phone = data.phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")
            data.password = state.formDataStep2.password
            data.confirmationPassword = state.formDataStep2.confirmationPassword
            try {
                let accountCreated = await createAccount(data)
                console.log("accountCreated", accountCreated)
                if (config.emailConfirmationApp) {
                    commit("RESET_FORM_DATA_STEP_3");
                    commit("SET_CURRENT_STEP", nextStep);
                    router.push("/register-form/" + nextStep);
                    commit("SET_LOADING_STEP", false);
                } else {
                    // send user to buy 
                    // commit("SET_LOADING_STEP", false);

                    console.log("fui para etapa de compra")
                }
            } catch (error) {
                console.log("error", error)
                commit("SET_LOADING_STEP", false);
                showNotificationError(error.response.data.message || 'Erro ao salvar os dados. Tente novamente mais tarde')


            }
        } else if (state.currentStep == 3) {

            let data = { ...state.formDataStep1, ...state.formDataStep2, ...config };
            data.nickname = data.username
            data.step = state.currentStep
            data.inscription = data.inscription.replaceAll(".", "").replaceAll("-", "")
            data.phone = data.phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")
            let confirmationCode = state.formDataStep3.confirmationCode.join('')
            data.confirmationCode = confirmationCode
            try {
                let accountCreated = await confirmAccount(data)
                showNotificationSuccess('Conta confirmada com sucesso!')
                commit("SET_LOADING_STEP", false);
                commit("purchase/SET_CURRENT_STEP", nextStep, { root: true })
                commit("purchase/SET_SHOW_STEPS", true, { root: true })
                let user = accountCreated.authToken
                user.stayConnected = true
                await commit('login/SET_USER_LOGGED', { user: user, logged: true }, { root: true })
                await commit('login/SET_ID_USER_ACCOUNT', accountCreated.idUserPlatformAccount, { root: true })
                
                await commit('login/SET_PLAYER_ID', accountCreated.playerId, { root: true })
                router.push("/buy-chips");

            } catch (error) {
                if (error?.response?.data?.message == 'O usuário escolhido já está cadastrado no aplicativo suprema poker') {
                    commit("SET_LOGIN_IN_USE", true);
                    commit("SET_CURRENT_STEP", previousStep);
                    router.push("/register-form/" + previousStep);

                }
                showNotificationError(error?.response?.data?.message || 'Erro ao confirmar a conta. Tente novamente mais tarde')
                commit("SET_LOADING_STEP", false);
                console.error('Erro ao confirmar a conta:', error);

            }

        }

    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
