<template>
  <v-container
    fluid
    fill-height
    class="register"
    :style="{ backgroundImage: `url(${style.backgroundImg})` }"
  >
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-img
          class="banner d-none d-sm-block"
          :src="style.bannerUrl"
          alt="Banner do clube"
          contain
        ></v-img>
        <v-img
          class="banner-mobile d-sm-none"
          :src="style.bannerMobileUrl"
          alt="Banner do clube para mobile"
          contain
        ></v-img> 

        <div class="register-button">
          <button
            class="larger-button-register"
            @click="redirectToRegisterForm"
          >
            CADASTRAR
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("configs", ["style"]),
  },
  created() {
    console.log("this.style", this.style);
  },
  methods: {
    redirectToRegisterForm() {
      this.$router.push("/register-form/1");
    },
  },
};
</script>

<style src="../styles/Register.css"></style>