<template>
  <div
    class="register"
    :style="{ backgroundImage: `url(${style.backgroundImg})` }"
  >
    <!-- <button class="larger-button-non-validation" @click="nextStepNonValidation()">
      AVANÇAR SEM VALIDAÇÃO
    </button> -->
    <FormComponent :totalSteps="totalSteps"  />
  </div>
</template>


<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FormComponent from "@/components/register/FormComponent.vue";

export default {
  components: {
    FormComponent,
  },
  created() {
    this.SET_CURRENT_STEP(this.$route.params.step);
  },
  computed: {
    ...mapState("configs", ["style"]),
  },
  data() {
    return {
      totalSteps: 6,
    };
  },
  methods: {
    ...mapActions("form", ["saveStepNonValidation"]),
    ...mapMutations("form", ["SET_CURRENT_STEP"]),

  },
};
</script>

<style src="../styles/Register.css"></style>